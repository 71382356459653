import { GetStaticProps } from "next";
import Head from "next/head";
import { Seomatic } from "react-seomatic";

import PageSections from "@components/PageSections";
import useSetHeaderTheme from "@hooks/useSetHeaderTheme";
import craft from "@pages/api/craft";
import { returnStaticProps } from "@lib/returnStaticProps";
import QueryHomepage from "@queries/QueryHomepage.graphql";
import { PreviewContent } from "@interfaces/PreviewContent.interface";
import getGridEntries from "@lib/getGridEntries";
import getEmbeddedForms from "@lib/getEmbeddedForms";

export const getStaticProps: GetStaticProps = async ({
	params,
	previewData: nextPreviewData,
	preview,
}) => {
	const previewData: PreviewContent = nextPreviewData as PreviewContent;

	const uri = "/";

	const response = await craft(
		QueryHomepage,
		{
			drafts:
				(process.env.VERCEL_ENV === "preview" ||
					process.env.NODE_ENV !== "production") &&
				null,
		},
		preview ? previewData.token : null
	);

	const { pageEntry, primaryNavigation, primaryCallToActions, globalSet } =
		response;
	//console.log(response.pageEntry.allPageSections);
	const gridPosts = await getGridEntries(response.pageEntry?.allPageSections);
	const forms = await getEmbeddedForms(response.pageEntry?.allPageSections);

	const returnResponse = await returnStaticProps(
		{
			navigation: {
				primaryNavigation,
				primaryCallToActions,
			},
			pageEntry,
			globalSet,
			gridPosts,
			forms,
		},
		!pageEntry,
		uri
	);

	return returnResponse;
};

export default function Page({ pageEntry, gridPosts, forms }): JSX.Element {
	useSetHeaderTheme(pageEntry.headerTheme);
	return (
		<>
			<Seomatic Head={Head} {...pageEntry.seomatic} />
			<PageSections
				sections={pageEntry.pageSections}
				gridPosts={gridPosts}
				forms={forms}
			/>
		</>
	);
}
